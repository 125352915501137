<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Statut spécifique" />
          </div>

          <div class="d-flex justify-center">
            <WarningEditComponent />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Détail d'un statut spécifique</div>
              </v-row>
            </v-card-title>

            <!-- l'application -->
            <v-card-text>
              <v-row no-gutters align="center" class="my-3">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">Application</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <div >{{ appName }}</div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le label -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">label</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le label"
                    dense
                    v-model="label"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- l'ordre -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">ordre</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="L'ordre"
                    dense
                    v-model="ranking"
                    :rules="[rules.noDoubleRanking,]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- la clé technique -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">clé technique</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="La clé technique"
                    dense
                    v-model="tid"
                    :rules="[rules.noDoubleTid,]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="mx-2 my-2 px-4 btn"
                color="primary"
                :disabled="!hasChanged"
                @click="save"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import WarningEditComponent from "@/components/ui/WarningEditComponent.vue";

import { TicketingStatusService } from "@/service/conf/ticketing_status_service.js";
import { ApplicationService } from "@/service/dictionary/applications_service.js";
import { TicketingObjectSubobjectService } from "@/service/conf/ticketing_object_subobject_service.js";

import { areTheSame } from "@/tools/string_tool.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "EditTicketingSpecificStatus",
  components: {
    Workflow,
    TitleAndReturnComponent,
    WarningEditComponent,
    AlertNotSavedModifsComponent,
  },

  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      service: null,
      /** le service des applications */
      serviceApplication: null,
      /** Le service des applications ticketing */
      serviceAppTicketing: null,

      /** l'identifiant à éditer */
      entityId: null,
      /** id de l'application  */
      entityAppId: null,

      /** le nom de l'application */
      appName: null,
      /** le label */
      label: null,
      /** l'ordre */
      ranking: null,
      /** la clé technique */
      tid: null,

      /**La donnée originale */
      source: null,

      /** La liste des statuts de l'app */
      statusApp: [],

      /** Les règles de saisie des composants */
      rules: {
        noDoubleRanking: value => !this.checkAlreadyHasRanking(value) || "Ce rang est déjà utilisé pour un autre statut.",
        noDoubleTid: value => !this.checkAlreadyHasTid(value) || "Cette clé technique existe déjà pour un statut de l'app.",
      }
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        // Récupération de l'application cible
        this.application = await this.serviceApplication.getById(this.entityAppId);
        this.appName = this.application.label;

        // Récupération de l'ensemble des statuts de l'app
        this.statusApp = await this.serviceAppTicketing.getAllStatusOfApplication(this.entityAppId);

        // Récupération du statut de l'app
        let entity = this.statusApp.find((s) => s.id == this.entityId);
        // Affectation de la source
        this.source = JSON.parse(JSON.stringify(entity));

        this.init();

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**initialiser les données à partir de l'objet source */
    init() {
      this.label = this.source.label;
      this.ranking = this.source.ranking;
      this.tid = this.source.tid;
    },

    async save() {
      // enregistrement de l'entity
      if (this.entityHasChanged) {
        try {
          let entity = JSON.parse(JSON.stringify(this.source));
          entity.label = this.label;
          entity.ranking = this.ranking;
          entity.tid = this.tid;

          await this.service.update(entity);
          
          this.disableAlertQuit();
          this.$router.go(-1);

        } catch (error) {
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement du statut : " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      }    
    },

    /** Check si un des statut de l'app contient déjà le rank */
    checkAlreadyHasRanking() {
      if (!this.statusApp) {
        return false;
      }

      // L'app utilise les staut spécifiques, on recherche le rank saisie
      let found = this.statusApp.find((a) => a.ranking.toString() === this.ranking && this.entityId != a.id);

      // La rank saisi est trouvé, affichage d'une erreur
      if (found) {
        return true;
      } else {
        return false;
      }
    },

    /**  Check si un des statut de l'app contient la même clé technique */
    checkAlreadyHasTid() {
      if (!this.statusApp) {
        return false;
      }
      
      // L'app utilise les statuts spécifiques, on check le tid
      let found = this.statusApp.find((a) => a.tid == this.tid && this.entityId != a.id);

      if (found) {
        return true;
      } else {
        return false;
      }    
    },
  },
  computed: {
    completed() {
      if (!this.label) return false;
      if (!this.ranking) return false;

      return true;
    },
    hasChanged() {
      if (!this.source) return false;

      if (!this.completed) return false;

      if (this.checkAlreadyHasRanking()) {
        return false;
      }

      let changed = false;

      if (this.entityHasChanged) {
        changed = true;
      }

      return changed;
    },
    /**retourne true si l'entité est modifié */
    entityHasChanged() {
      let changed = false;

      if (!areTheSame(this.source.label, this.label)) {
        changed = true;
      }
      if (!areTheSame(this.source.ranking, this.ranking)) {
        changed = true;
      }
      if (!areTheSame(this.source.tid, this.tid)) {
        changed = true;
      }

      return changed;
    },
  },
  mounted() {
    //instanciation des services d'accès aux APIs
    this.service = new TicketingStatusService(this.$api.getTicketingStatus());
    this.serviceApplication = new ApplicationService(this.$api.getApplicationApi());
    this.serviceAppTicketing = new TicketingObjectSubobjectService(
      this.$api.getTicketingObjectSubobject()
    );

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.entityId = this.$route.params.id;
    }
    // Récupération des paramètres
    if (this.$route.query) {
      if (this.$route.query.app) {
        this.entityAppId = parseInt(this.$route.query.app);
      }
    }

    this.load();
  },
};
</script>

<style>
</style>